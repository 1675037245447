/*************************************** Fonts *****************************************/
@font-face {
	font-family: "Public Sans";
	src: url("assets/fonts/public-sans/publicsans-regular-webfont.woff2")
			format("woff2"),
		url("assets/fonts/public-sans/publicsans-regular-webfont.woff")
			format("woff");
	font-weight: normal;
	font-style: normal;
}

/* The root element. This can be found by inspecting the webpage source code */
#root {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

/*
$navy: #002e5d;
$royal: #0062b8;
$white: #ffffff;
$gray: #666666;
$dark-gray: #141414;
$light-gray: #c5c5c5;
$lightest-gray: #e6e6e6;
*/

/* General */

body {
	overflow-y: scroll;
}

/*
* Fix the flashing sign in button
* Response found here: https://github.com/byuweb/byu-user-info-oauth/issues/2
*/
byu-user-info-oauth {
	display: none;
}

.required-indicator{
	color: red;
	margin-left: 4px;
}

.required-indicator::before{
	content: "*";
}

.byu-component-rendered byu-user-info-oauth {
	display: block;
}

.mimic-byu-header-a {
	padding: 0;
	margin: 0;
	border: 0;
	color: white;
}

.mimic-byu-header-a:hover {
	background-color: inherit;
}

/****************** ABSTRACTS TABLE ************************/
th span {
	cursor: pointer;
}

.abstractRow {
    cursor: pointer;
	transition: 0.2s;
}

/* The session page has slightly different styling for its abstracts*/

.sessionAbstractRow {
	cursor: pointer;
	transition: 0.2s
}

.sessionAbstractRow:hover {
	background-color: rgba(0,0,0,0.1);
}

/* TODO: integrate it in a more DRY way */

.abstractRow:hover {
	background-color: rgba(0, 0, 0, 0.1);
}

.abstractRow > td {
	padding-top: 10px;
	padding-bottom: 10px;
	min-width: 25ch;
	max-width: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.sticky-table-header > tr > th {
	position: sticky;
	top: 0;
	white-space: nowrap;
	background-color: white;
	z-index: 5;
}

@media screen and (max-width: 48em){
	.optionalColumn {
		display: none;
	}
}

.modal-header {
	background-color: red;
}

.modal-footer {
	margin-bottom: 0;
	background-color: red;
}


/* for dropdown menu */
.dropdown {
	display: inline-block;
}
.dropdown-btn {
	border: none;
	color: #002E5D;
	background-color: white;
	font-family: 'HCo Ringside Narrow SSm', Arial, sans-serif;
	font-size: 1rem!important;
	padding: 11px 22px!important;
	display: block;
}
.dropdown-btn:hover, .dropdown-btn:active {
	background-color: #e6e6e6;
}
.dropdown-child {
	display: none;
	min-width: 200px;
	position: absolute;
	background-color: white;
	box-shadow: 0 4px 8px #c5c5c5;
}
.dropdown-child a {
	color: #002e5d;
	font-size: 16px;
	padding: 12px;
	text-decoration: none;
	display: block;
}
.dropdown-child a:hover {
	background-color: #e6e6e6;
}
.dropdown:hover .dropdown-child {
	display: block;
	background-color: white;
}

@media (max-width: 768px) {
	.hide-on-mobile {
	  display: none;
	}
  }
  
byu-footer {
	margin-top: auto;
}